<template>
  <div class="crafting-wrapper" :class="{ 'max-height': isConfirmationOpen }">
    <CraftingConfirmScreen
      v-if="isConfirmationOpen && tokenMetadataComputed"
      :recipe="currentRecipe"
      :token-metadata="tokenMetadataComputed"
      :crafting-multiplier="craftingMultiplier"
    />
    <CraftingDescriptionPopup v-if="isDescriptionOpen" />
    <div class="crafting-container">
      <div class="crafting-header">
        <div class="crafting-header-icons" @click="handleToggleDescriptionModal">
          <el-tooltip
            :disabled="$device.isMobile"
            placement="bottom-end"
            popper-class="crafting-header-tooltip-text is-customized"
          >
            <template #content>
              <p class="crafting-confirm" v-html="$t('craftingDescription')"></p>
            </template>
            <div class="crafting-header-icons-info" />
          </el-tooltip>
          <!-- !!! Temporary hide this block until the statistics will be implemented, please do not delete -->

          <!-- <el-tooltip placement="bottom-start" popper-class="crafting-header-tooltip-text is-customized">
            <template #content>
              <p>{{ $t('craftingStatsText') }}</p>
            </template>
            <NuxtLink to="/global-stats">
              <div class="crafting-header-icons-stats" />
            </NuxtLink>
          </el-tooltip> -->
        </div>
        <div class="crafting-text">
          <h2 class="crafting-title">{{ $t(props.selectedBuilding.buildingKey) }}</h2>
          <p class="crafting-description">{{ $t(props.selectedBuilding.descriptionKey || '') }}</p>
        </div>
      </div>
      <div class="claimable-tokens">
        <template v-for="(recipeActivity, index) of userActivityRef" :key="index">
          <CraftingReadyForClaimMaterials
            v-for="activityItem of recipeActivity"
            :key="activityItem.id"
            :activity-item="activityItem"
            :token-metadata-computed="tokenMetadataComputed"
            :selected-recipe-id="recipes?.[index]?.recipeId"
            :recipe="recipes?.[index]"
            :disabled="isClaiming"
            @claim-load-state-changed="(isLoading) => (isClaiming = isLoading)"
          />
        </template>
      </div>

      <div class="crafting-content">
        <div class="crafting-available-craft">
          <h2 class="crafting-available-craft-title">{{ $t('craftingRecipesTitle') }}</h2>
          <div class="crafting-available-craft-title-mobile">
            <img
              class="crafting-available-craft-title-mobile-arrow"
              :class="{ disabled: (scrollLeftRef || 0) <= 0 }"
              src="/img/icons/arrowRightThin.svg"
              alt="arrowLeft"
              @click="handleScrollRecipes(false)"
            />
            <h3>{{ $t('craftingChooseRecipe') }}</h3>
            <img
              class="crafting-available-craft-title-mobile-arrow"
              :class="{
                disabled:
                  scrollLeftRef >= (craftingListDOMRef?.scrollWidth || 0) - (craftingListDOMRef?.clientWidth || 0)
              }"
              src="/img/icons/arrowRightThin.svg"
              alt="arrowRight"
              @click="handleScrollRecipes(true)"
            />
          </div>
          <div
            ref="craftingListDOMRef"
            class="crafting-list"
            @scroll="(e) => (scrollLeftRef = (e?.target as HTMLElement)?.scrollLeft)"
          >
            <div
              v-for="(recipe, index) of recipes"
              :key="index"
              class="crafting-item"
              :class="[recipe?.recipeId === selectedRecipeId && 'selectedItem']"
              @click="handleSelectItemToCraft(recipe?.recipeId, index)"
            >
              <figure class="crafting-item-figure">
                <img
                  v-if="tokenMetadataComputed"
                  :src="getTokenImg(index)"
                  :alt="$getAltAttribute(recipe.products[0])"
                />
              </figure>
              <h4 v-if="tokenMetadataComputed" lang="ru" class="crafting-item-name">
                {{ $t(tokenMetaData(blockchain.contracts, recipe.products[0].tokenAddress)?.label || '') }}
              </h4>
            </div>
          </div>
        </div>
        <div v-if="selectedRecipeId" class="crafting-table">
          <CraftingProduceItem
            v-if="tokenMetadataComputed"
            :token-metadata="tokenMetadataComputed"
            :recipe-id="selectedRecipeId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, computed, provide, watch, onBeforeUnmount } from 'vue';
import { useRuntimeConfig } from 'nuxt/app';
import { BrowserProvider, Contract, ethers, getBytes, keccak256 } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/vue';
import { ElNotification } from 'element-plus';
import { useMainStore } from '~/stores/mainStore';
import { BigNumber } from 'bignumber.js';

import { CraftingProduceItem, CraftingReadyForClaimMaterials, CraftingConfirmScreen } from '#components';
import useEnvs from '~/composables/useEnvs';
import useAbiAccess from '~/composables/useAbiAccess';
import type { TNullable } from '~/types/common';
import type {
  BuildingProps,
  UserActivityAvailableForClaim,
  ActiveUsersModalProps,
  RecipeProduct,
  RecipeProps
} from '~/types/crafting';
import { tokenMetaData, modifyHistoryHashStatus, saveHashToLocalStorage } from '~/utils';
import useSendContractMethod from '~/composables/useSendContractMethod';
import { useI18n } from '#imports';
import abi from '@/public/abi/abi.json';
import getUserActivities from '~/utils/userActivityManager';
import { TokenUniquenessType } from '~/utils/constants';
import { capitalizeFirstLetter } from '~/utils/formatters';

const props = defineProps<{
  selectedBuilding: BuildingProps;
}>();

const { t } = useI18n();
const route = useRoute();
const { $getIconToken, $isShip } = useNuxtApp();
const { getContract } = useAbiAccess();
const store = useMainStore();
const { walletProvider } = useWeb3ModalProvider();
const { address } = useWeb3ModalAccount();
const { sendContractMethod } = useSendContractMethod();
const { apiUrl, blockchain } = useEnvs();

const config = useRuntimeConfig();
const { isMobile } = useDevice();
const selectedRecipeId = ref<TNullable<string>>();

const craftingContract = await getContract('crafting', blockchain.contracts.crafting);

const userActivityRef = reactive<UserActivityAvailableForClaim[][]>([]);

const isConfirmationOpen = ref<boolean>(false);
const isDescriptionOpen = ref<boolean>(false);

const confirmationNumberRef = ref<number>(0);
const isItemCrafting = ref(false);
const craftingMultiplier = ref<number>(1);
const craftingTime = ref<string>('');
const craftingListDOMRef = ref<HTMLElement | null>(null);
const scrollLeftRef = ref<number>(0);
const socketRef = ref();
// const buildingHash = computed(() => props.selectedBuilding.buildingHash || ZeroHash);
const isClaiming = ref(false);

provide('handleProduceItem', handleProduceItem);

provide('handleToggleConfirmationModal', handleToggleConfirmationModal);
provide('handleToggleDescriptionModal', handleToggleDescriptionModal);

provide('refreshList', refreshList);
provide('setProductMultiplier', setProductMultiplier);
provide('setCraftingTime', setCraftingTime);
provide('craftingMultiplier', craftingMultiplier);
provide('craftingTime', craftingTime);
provide('confirmationNumberRef', confirmationNumberRef);
provide('isItemCrafting', isItemCrafting);
provide('userActivity', userActivityRef);

const activeUsersModal = ref<ActiveUsersModalProps>({
  craftingViewersCount: 0
});
provide('activeUsersModal', activeUsersModal);

const { data: recipes } = useNuxtData<RecipeProps[]>(
  `buildingRecipes${isMobile && route.name !== 'districts' ? props.selectedBuilding.buildingHash : ''}`
);

watch(
  recipes,
  (newRecipes) => {
    if (newRecipes) {
      selectedRecipeId.value = newRecipes[0]?.recipeId;
    }
  },
  { immediate: true }
);

watch(
  [selectedRecipeId, recipes, () => store.notificationUpdateVersion],
  async (newRecipeId) => {
    if (!newRecipeId || !recipes.value || !address.value || !craftingContract) return;

    const userActivity = await getUserActivities(
      recipes.value,
      props.selectedBuilding,
      address.value,
      craftingContract
    );
    userActivityRef.splice(0, 4, ...userActivity);
  },
  {
    immediate: true
  }
);

watch(
  [recipes],
  () => {
    if (!recipes.value || !recipes.value.length) return;

    handleSelectItemToCraft(recipes.value[0].recipeId, 0);
  },
  { immediate: true }
);

const currentRecipe = computed(() => recipes.value?.find((recipe) => recipe?.recipeId === selectedRecipeId.value));
provide('currentRecipe', currentRecipe);

const isNotLocal = computed(() => config?.public.env === 'prod' || config?.public.env === 'test');
const computedSocketRef = computed(() =>
  `${apiUrl}/${`ws/crafting/viewers?buildingId=${props.selectedBuilding.buildingHash}&recipeId=${selectedRecipeId.value}`}`.replace(
    'https',
    isNotLocal.value ? 'wss' : 'ws'
  )
);

const socketUrl = computedSocketRef.value;

const socket = new WebSocket(socketUrl);
socketRef.value = socket;

socket.onopen = () => {
  socket.send('connected');
};

socket.onmessage = ({ data }: any) => {
  const parsedData = JSON.parse(data);
  activeUsersModal.value = parsedData;
};

socket.onclose = () => {
  console.log('closing socket');
};

if (document.hidden) {
  socket.close();
}

onBeforeUnmount(() => {
  socket.close();
});

const handleScrollRecipes = (isNext: boolean) => {
  craftingListDOMRef.value?.scroll({
    left: isNext ? scrollLeftRef.value + 150 : scrollLeftRef.value - 150,
    behavior: 'smooth'
  });
};

const tokenMetadataComputed = computed(() => {
  if (!currentRecipe.value?.products.length) return null;

  if (currentRecipe.value) {
    return tokenMetaData(blockchain.contracts, currentRecipe.value?.products[0].tokenAddress);
  }
  return null;
});

const isNft = computed(() => {
  if (!currentRecipe.value) return false;

  return (
    currentRecipe.value &&
    currentRecipe.value.products.every(
      (product: RecipeProduct) => product.nftValueMin !== null && product.nftValueMax !== null
    )
  );
});

const tokenUniqueness = computed(() => {
  if (!currentRecipe.value) return '';

  const product = currentRecipe.value?.products[0];
  const value = getNftValueByAddress(blockchain.contracts, product);

  return value;
});

function tokenTokenUniquenessFormatter(key: TokenUniquenessType | string): string {
  switch (key) {
    case TokenUniquenessType.Common:
      return t('coreCommon');
    case TokenUniquenessType.Uncommon:
      return t('coreUncommon');
    case TokenUniquenessType.Rare:
      return t('coreRare');
    default:
      return '';
  }
}

function setProductMultiplier(multiplier: number) {
  craftingMultiplier.value = multiplier;
}

function setCraftingTime(formattedTime: string) {
  craftingTime.value = formattedTime;
}

async function refreshList() {
  if (!recipes.value || !address.value || !craftingContract) return;

  return await getUserActivities(recipes.value, props.selectedBuilding, address.value, craftingContract);
}

function handleSelectItemToCraft(recipeId: string, index: number) {
  const currentRecipeImage = getTokenImg(index);
  setTokenImgToStorage(currentRecipeImage);
  selectedRecipeId.value = recipeId;
}

function getTokenImg(index: number): string {
  if (!recipes.value) return '';

  const img = $getIconToken(
    recipes.value[index].products[0],
    $isShip(recipes.value[index].products[0])
      ? getShipIndexByRecipeId(BigNumber(recipes.value[index].recipeId || 0).toNumber())
      : undefined
  );

  return img;
}

function setTokenImgToStorage(img: string): void {
  store.setCraftingTokenImg(img);
}

function handleToggleConfirmationModal() {
  isConfirmationOpen.value = !isConfirmationOpen.value;
}

function handleToggleDescriptionModal() {
  if (isMobile) isDescriptionOpen.value = !isDescriptionOpen.value;
}

async function handleProduceItem() {
  isItemCrafting.value = true;

  const provider = new BrowserProvider(walletProvider.value as any);
  const signer = await provider.getSigner();

  const craftingContract = new Contract(blockchain.contracts.crafting, abi.crafting, signer);

  const nonce = await craftingContract.getNonce(address.value);

  const digest = keccak256(
    ethers.AbiCoder.defaultAbiCoder().encode(
      ['address', 'uint256', 'uint256'],
      [blockchain.contracts.crafting, nonce, blockchain.networkId]
    )
  );

  try {
    const signature = await signer.signMessage(getBytes(digest));

    const { r, s, v } = ethers.Signature.from(signature);

    const produceTx = await sendContractMethod(
      {
        contract: 'crafting',
        address: blockchain.contracts.crafting,
        methodName: 'useRecipe',
        methodArguments: [
          props.selectedBuilding?.buildingHash,
          Number(selectedRecipeId.value ?? 0),
          craftingMultiplier.value,
          v,
          r,
          s
        ]
      },
      () => {
        confirmationNumberRef.value = confirmationNumberRef.value + 1;
      }
    );

    if (!produceTx?.hash) return;

    let description: string = `${tokenMetadataComputed.value?.methodName} ${capitalizeFirstLetter(tokenUniqueness.value)}`;

    if (!isNft.value) {
      const values = currentRecipe.value?.products.map((product) => {
        if (new BigNumber(product.quantityMin).eq(product.quantityMax)) {
          return BigNumber(product.quantityMin).multipliedBy(craftingMultiplier.value).decimalPlaces(1);
        }

        const minValue = new BigNumber(product.quantityMin).multipliedBy(craftingMultiplier.value).decimalPlaces(1);
        const maxValue = new BigNumber(product.quantityMax).multipliedBy(craftingMultiplier.value).decimalPlaces(1);

        return `${minValue}-${maxValue}`;
      });

      description = `(${values} ${t(tokenMetadataComputed.value?.methodName || '')})`;
    }

    saveHashToLocalStorage(`${t('craftingCraftingTab')} ${description}`, produceTx.hash);
    store.updateVersion();

    await produceTx?.wait(blockchain.minConfirmationsCount);
    const confirmations = await produceTx?.confirmations();

    if (confirmations >= blockchain.minConfirmationsCount && produceTx?.hash) {
      modifyHistoryHashStatus(produceTx.hash, 'Done');
      confirmationNumberRef.value = 0;
      store.updateVersion();

      const params = {
        recipeId: selectedRecipeId.value,
        transactionHash: produceTx.hash,
        userAddress: address.value,
        landBuildingHash: props.selectedBuilding?.buildingHash
      };

      await fetch(apiUrl + `/crafting/activity`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      });
    }

    ElNotification.success({
      title: `${t('craftingCraftingTab')} (${craftingMultiplier.value} ${t(tokenMetadataComputed.value?.label || '')} ${tokenTokenUniquenessFormatter(tokenUniqueness.value)})`,
      message: ''
    });
  } catch (error) {
    console.error(error);
    ElNotification.error({
      title: t('craftingErrorWhileCrafting'),
      message: ''
    });
  } finally {
    isConfirmationOpen.value = false;
    isItemCrafting.value = false;
  }
}
</script>

<style lang="scss">
.el-dialog.crafting.produce {
  max-width: 850px;

  .el-dialog {
    &__body {
      padding-top: 0 !important;
    }
  }
}

.crafting-material {
  margin-left: 7px;
}

.crafting-wrapper {
  font-family: Grenze, sans-serif;
}

.claimable-tokens {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 200px;
  overflow: auto;
}

.claimable-blocks-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.ready-for-claim {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  width: 100%;
  max-width: 48%;
  border-radius: 12px;
  max-height: 64px;
  border: 2px solid #1e353e;

  @media screen and (max-width: 767px) {
    max-height: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  background: linear-gradient(180deg, #60ff7c 0%, #04202b 100%);

  button {
    margin-left: 20px;
    height: 40px;
    line-height: 27px;
    vertical-align: middle;
    padding-top: 7px;
    padding-bottom: 7px;

    svg {
      margin-left: 8px;
      path {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: #fff !important;
        }
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
}

.el-popper.is-customized .el-popper__arrow::before {
  display: none !important;
}

.crafting-item-figure {
  max-width: 50px;
  max-height: 50px;
  display: flex;
  align-items: center;
  margin: 0;
  font-family: Eczar, sans-serif;

  p {
    margin-bottom: 0;
    margin-left: 5px;
    font-weight: 800;
    font-size: 24px;
    color: #fff;
    text-wrap: nowrap;
  }

  img {
    width: 100%;
  }
}

.crafting {
  &-header {
    position: relative;

    @media screen and (max-width: 767px) {
      position: static;
    }

    &-tooltip-text {
      background: #000000bf !important;
      color: #fff !important;
      font-size: 20px !important;
      font-weight: 500;
      padding: 18px 15px !important;
      border-radius: 15px !important;
      line-height: 1;

      border: 1px solid #1e353e;
      max-width: 370px;

      text-align: center;
    }

    &-icons {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;

      &-stats,
      &-info {
        cursor: pointer;

        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;

        &:hover {
          opacity: 0.6;
        }
      }

      &-stats {
        background-image: url('~/public/img/icons/stats.svg');
      }
      &-info {
        margin-right: 15px;
        background-image: url('~/public/img/icons/info.svg');
      }

      @media screen and (max-width: 900px) {
        top: 23px;
        left: 20px;
      }
    }
  }

  &-mobile-header {
    display: none;

    h2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }

    @media screen and (max-width: 900px) {
      display: block;
    }
  }

  &-content {
    display: flex;
    margin-top: 16px;
    align-items: stretch;
    min-height: 420px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    position: relative;

    & > img {
      max-width: 215px;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      position: static;
    }
  }

  &-available-craft {
    position: relative;

    width: 100%;
    max-width: 180px;

    padding: 10px 7px;

    background: #0d242f;
    border: 1px solid #1e353e;
    border-radius: 15px;

    &-title {
      color: #fff;
      text-align: center;
      margin-bottom: 10px;
      margin-top: 0;
      display: block;

      &-mobile {
        display: none;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;

        color: #90989d;
        font-weight: 500;
        font-size: 18px;

        h3 {
          margin: 0 10px;
        }

        &-arrow {
          &:nth-child(1) {
            transform: rotate(180deg);
          }
          &.disabled {
            opacity: 0.3;
          }
        }
      }

      @media screen and (max-width: 900px) {
        display: none;

        &-mobile {
          display: flex;
        }
      }
    }

    @media screen and (max-width: 900px) {
      max-width: 100%;
    }
  }

  &-list {
    height: 100%;
    max-height: 390px;
    overflow-y: auto;
    scrollbar-width: none;

    @media screen and (max-width: 900px) {
      overflow-y: hidden;
      display: flex;
      overflow-x: auto;
      max-width: 100%;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 8px 7px;

    @media screen and (max-width: 900px) {
      flex: 0 0 150px;
    }

    &.selectedItem {
      background: linear-gradient(180deg, #0083ad 0%, #00354f 100%), #081b25;
    }

    background: linear-gradient(190.74deg, #425b6a 7.97%, #0e1b25 95.05%);

    border: 1px solid #1e353e;
    border-radius: 12px;

    transition: all 0.3s ease;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 9px;

      @media screen and (max-width: 900px) {
        margin-top: 0;
        margin-left: 10px;
      }
    }

    &:hover {
      background: linear-gradient(180deg, #0083ad 0%, #00354f 100%), #081b25;
    }

    &-figure {
      width: 44px;
      height: 44px;
      min-height: 44px;
      min-width: 44px;
      display: flex;
      align-items: center;
      margin: 0;
      font-family: Eczar, sans-serif;

      img {
        width: 100%;
        border-radius: 8px;
      }

      p {
        margin-bottom: 0;
        margin-left: 5px;
        font-weight: 800;
        font-size: 24px;
        color: #fff;
        text-wrap: nowrap;
      }
    }

    &-name {
      color: #fff;
      font-weight: 500;
      font-size: 17px;
      margin-bottom: 0px;
      margin-left: 10px;
      text-align: left;
      word-break: break-word;
      overflow-wrap: break-word;
      hyphens: auto;

      @media screen and (max-width: 900px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &-table {
    width: 100%;
    padding-left: 10px;

    @media screen and (max-width: 900px) {
      padding-left: 0;
      padding-top: 10px;
    }
  }

  &-text {
    text-align: center;
    margin-bottom: 0;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &-title {
    color: #fff;

    font-weight: 700;
    font-size: 32px;

    margin: 0;
    margin-bottom: 15px;
  }

  &-description {
    color: #90989d;
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
    max-width: 670px;
    margin: auto;
  }

  &-materials {
    margin: 0;
    padding: 0;

    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
  }

  &-recipe {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }
  }

  &-arrow {
    font-size: 32px;
    color: #88e1ff;
    margin: 0 10px;

    @media screen and (max-width: 900px) {
      transform: rotate(90deg);
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &-crafted {
    padding: 0px 11px;
    border: 1px solid #1e353e;
    border-radius: 12px;

    background: #041a27;
    box-shadow: 2px 4px 5px 0px #00000040;
    background: linear-gradient(180deg, #0083ad 0%, #00354f 100%);
  }

  &-material {
    position: relative;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    font-family: Eczar, sans-serif;

    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    background: #041a27;
    border: 1px solid #1e353e;
    border-radius: 12px;

    &-img {
      height: 50px;
      width: 50px;
    }

    &-amount {
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: #cfd3dc;
    }
  }

  &-confirm {
    span {
      color: #0083ad;
    }
  }

  .el-input-number {
    width: 130px;
    flex-shrink: 0;
    @media screen and (max-width: 500px) {
      width: 160px;
      height: 48px;

      &__decrease,
      &__increase {
        width: 32px;
      }
    }

    @media screen and (max-width: 376px) {
      width: auto;
      min-width: 120px;
    }

    .el-input {
      background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%) !important;

      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
      font-size: 18px;

      &__inner {
        color: #cfd3dc;
        --el-input-inner-height: 32px;
        @media screen and (max-width: 500px) {
          --el-input-inner-height: 32px;
        }
      }

      &__wrapper {
        background: radial-gradient(59.02% 71.08% at 84.01% 85.82%, #040f14 0%, #081b25 100%) !important;

        @media screen and (max-width: 500px) {
          padding: unset;
        }
        input {
          padding: 0;
        }
      }
    }
  }
}

.max-height {
  height: 90vh;
  overflow: hidden;

  @media (max-width: 479px) {
    height: 70vh;
    overflow-y: auto;
  }
}
</style>
